// @flow

// eslint-disable-next-line no-use-before-define
export function modifierClassName(modifier: AllowedModifier): string {
  return `fa-${modifier}`;
}

// START_GENERATED_CODE
// Do not make manual edits here, they will be overwritten!
// Recreate this content by running:
//
//     just deps generate-font-awesome-v6-icons
//

//
// List of available icon modifiers from Font Awesome docs
// https://docs.fontawesome.com/web/style/style-cheatsheet
//

//
// Sizing Icons
//
/** Changes an icon's font-size to 1em */
type Modifier1x = '1x';
/** Changes an icon's font-size to 2em */
type Modifier2x = '2x';
/** Changes an icon's font-size to 3em */
type Modifier3x = '3x';
/** Changes an icon's font-size to 4em */
type Modifier4x = '4x';
/** Changes an icon's font-size to 5em */
type Modifier5x = '5x';
/** Changes an icon's font-size to 6em */
type Modifier6x = '6x';
/** Changes an icon's font-size to 7em */
type Modifier7x = '7x';
/** Changes an icon's font-size to 8em */
type Modifier8x = '8x';
/** Changes an icon's font-size to 9em */
type Modifier9x = '9x';
/** Changes an icon's font-size to 10em */
type Modifier10x = '10x';
/** Changes an icon's font-size to 0.625em (~10px) and also vertically aligns icon */
type Modifier2xs = '2xs';
/** Changes an icon's font-size to .75em (~12px) and also vertically aligns icon */
type ModifierXs = 'xs';
/** Changes an icon's font-size to 0.875em (~14px) and also vertically aligns icon */
type ModifierSm = 'sm';
/** Changes an icon's font-size to 1.25em (~120px) and also vertically aligns icon */
type ModifierLg = 'lg';
/** Changes an icon's font-size to 1.5em (~24px) and also vertically aligns icon */
type ModifierXl = 'xl';
/** Changes an icon's font-size to 2em (~32px) and also vertically aligns icon */
type Modifier2xl = '2xl';

//
// Fixed-Width Icons
//
/** Sets an icon to display at a fixed width for easy vertical alignment */
type ModifierFw = 'fw';

//
// Icons in a List
//
/** Used on a <ul> or <ol> elements to style icons as list bullets */
type ModifierUl = 'ul';
/** Used on individual <li> elements to style icons as list bullets */
type ModifierLi = 'li';

//
// Rotating Icons
//
/** Rotates an icon 90° */
type ModifierRotate90 = 'rotate-90';
/** Rotates an icon 180° */
type ModifierRotate180 = 'rotate-180';
/** Rotates an icon 270° */
type ModifierRotate270 = 'rotate-270';
/** Mirrors an icon horizontally */
type ModifierFlipHorizontal = 'flip-horizontal';
/** Mirrors an icon vertically */
type ModifierFlipVertical = 'flip-vertical';
/** Mirrors an icon both vertically and horizontally */
type ModifierFlipBoth = 'flip-both';
/** Rotates an icon by a specific degree - setting an accompanying valid inline value for --fa-rotate-angle is required */
type ModifierRotateBy = 'rotate-by';

//
// Animating Icons
//
/** Makes an icon spin 360° clock-wise */
type ModifierSpin = 'spin';
/** Makes an icon spin 360° clock-wise in 8 incremental steps */
type ModifierSpinPulse = 'spin-pulse';
/** When used in conjunction with fa-spin or fa-spin-pulse, makes an icon spin counter-clockwise */
type ModifierSpinReverse = 'spin-reverse';
/** Makes an icon scale up and down */
type ModifierBeat = 'beat';
/** Makes an icon visually fade in and out using opacity */
type ModifierFade = 'fade';
/** Makes an icon rotate about the Y axis */
type ModifierFlip = 'flip';

//
// Bordered Icons
//
/** Creates a border with border-radius and padding applied around an icon' */
type ModifierBorder = 'border';

//
// Pulled Icons
//
/** Pulls an icon by floating it left and applying a margin-right */
type ModifierPullLeft = 'pull-left';
/** Pulls an icon by floating it right and applying a margin-left */
type ModifierPullRight = 'pull-right';

//
// Stacking Icons
//
/** Used on the icon which should be displayed at base size when stacked */
type ModifierStack1x = 'stack-1x';
/** Used on the icon which should be displayed larger when stacked */
type ModifierStack2x = 'stack-2x';
/** Inverts the color of the icon displayed at base size when stacked */
type ModifierInverse = 'inverse';

//
// Duotone Icons
//
/** Swap the default opacity of each layer in a duotone icon (making an icon's primary layer have the default opacity of 40% rather than its secondary layer) */
type ModifierSwapOpacity = 'swap-opacity';

//
// Accessibility
//
/** Visually hides an element containing a text-equivalent for an icon while keeping it accessible to assistive technologies */
type ModifierSrOnly = 'sr-only';
/** Used alongside fa-sr-only to show the element again when it's focused (e.g. by a keyboard-only user) */
type ModifierSrOnlyFocusable = 'sr-only-focusable';

type AllowedModifier =
  | Modifier1x
  | Modifier2x
  | Modifier3x
  | Modifier4x
  | Modifier5x
  | Modifier6x
  | Modifier7x
  | Modifier8x
  | Modifier9x
  | Modifier10x
  | Modifier2xs
  | ModifierXs
  | ModifierSm
  | ModifierLg
  | ModifierXl
  | Modifier2xl
  | ModifierFw
  | ModifierUl
  | ModifierLi
  | ModifierRotate90
  | ModifierRotate180
  | ModifierRotate270
  | ModifierFlipHorizontal
  | ModifierFlipVertical
  | ModifierFlipBoth
  | ModifierRotateBy
  | ModifierSpin
  | ModifierSpinPulse
  | ModifierSpinReverse
  | ModifierBeat
  | ModifierFade
  | ModifierFlip
  | ModifierBorder
  | ModifierPullLeft
  | ModifierPullRight
  | ModifierStack1x
  | ModifierStack2x
  | ModifierInverse
  | ModifierSwapOpacity
  | ModifierSrOnly
  | ModifierSrOnlyFocusable;

// END_GENERATED_CODE
