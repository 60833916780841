// @flow

import * as React from 'react';
import cx from 'classnames';
import Honeybadger from '@honeybadger-io/js';

import { type Props as FontAwesomeIconProps } from 'common/components/FontAwesomeIcon';
import { allSolidIcons } from './solidIcons';
import { fontAwesomeFourNameToIcon } from './shims';
import { modifierClassName } from './modifiers';
import { solidIconAliases } from './possibleAliases';

type Props = {
  ...FontAwesomeIconProps,
  icon: {| legacyClassName: string |},
  ...
};

export default function LegacyIcon(props: Props): React.Node {
  const { icon, className, spin, fixedWidth, pulse, ...otherProps } = props;

  const unknownIcons = [];

  const transformedClassNames = icon.legacyClassName
    .split(' ')
    .reduce((acc, nextPossibleIconName) => {
      if (nextPossibleIconName === 'fa') {
        // We don't need the plain `fa` class at all anymore.
        return acc;
      }
      if (!nextPossibleIconName.startsWith('fa-')) {
        // We pass through any class names which don't look like Font Awesome icon names
        return [...acc, nextPossibleIconName];
      }

      const faName = nextPossibleIconName.slice(3);

      const mappedName = fontAwesomeFourNameToIcon[faName];
      if (mappedName) {
        // We found a match from the shims! Let's use that.
        const [iconFamily, iconName] = mappedName;
        return [...acc, `fa-${iconFamily}`, `fa-${iconName}`];
      }

      const aliasName = solidIconAliases[faName];
      if (aliasName) {
        // We found a match from the aliases! Let's use that.
        const [iconFamily, iconName] = aliasName;
        return [...acc, `fa-${iconFamily}`, `fa-${iconName}`];
      }

      // Then we handle modifiers
      switch (faName) {
        case 'fw':
          return [...acc, modifierClassName('fw')];
        case 'spin':
          return [...acc, modifierClassName('spin')];
        case 'stack-1x':
          return [...acc, modifierClassName('stack-1x')];
        case 'stack-2x':
          return [...acc, modifierClassName('stack-2x')];
        case 'lg':
          return [...acc, modifierClassName('lg')];
        default:
        // Other modifiers aren't used based on search through codebase and CI
        // running tests. If there are other modifiers, they would show up.
      }

      // Now we can assume that the icon name is an icon with a solid name
      // as long as it's in one of the available solid icons
      if (
        allSolidIcons.some(
          ([_iconFamily, solidIconName]) => solidIconName === faName
        )
      ) {
        return [...acc, 'fa-solid', `fa-${faName}`];
      }

      // This means that we received some icon which we didn't have a mapping for
      unknownIcons.push({ iconFamily: 'solid', iconName: faName });
      // Only add the fa-solid class once in the error case
      if (acc.includes('fa-solid')) return acc;
      return [...acc, 'fa-solid'];
    }, ([]: Array<string>));

  if (unknownIcons.length > 0) {
    const missingIcons = unknownIcons.map(
      ({ iconFamily, iconName }) => `${iconFamily} ${iconName}`
    );
    if (
      window.__ENVIRONMENT__ === 'staging' ||
      window.__ENVIRONMENT__ === 'production'
    ) {
      Honeybadger.notify({
        message: `Tried to render missing icon(s): ${missingIcons.join(', ')}`,
        context: {
          legacyIconProps: props
        }
      });
    } else {
      throw new Error(
        `Tried to render missing icon(s): ${missingIcons.join(', ')}`
      );
    }
  }

  const classNameForIcon = cx(transformedClassNames, className, {
    'fa-fw': fixedWidth,
    'fa-spin': spin,
    'fa-pulse': pulse
  });

  // eslint-disable-next-line rulesdir/no-plain-icons -- This is expected to all be legacy icon calls
  return <i {...otherProps} className={classNameForIcon} />;
}
