// @flow

// START_GENERATED_CODE
// Do not make manual edits here, they will be overwritten!
// Recreate this content by running:
//
//     just deps generate-font-awesome-v6-icons
//

import {
  type Icon,
  brandsSquareFacebook,
  brandsSquareTwitter,
  brandsYoutube,
  regularBuilding,
  regularCalendarCheck,
  regularCircle,
  regularCircleCheck,
  regularCircleXmark,
  regularClock,
  regularComment,
  regularCommentDots,
  regularFile,
  regularFileAudio,
  regularFileExcel,
  regularFileImage,
  regularFileLines,
  regularFilePdf,
  regularFileWord,
  regularFileZipper,
  regularFiles,
  regularFloppyDisk,
  regularGem,
  regularHeart,
  regularImage,
  regularPaperPlane,
  regularPenToSquare,
  regularRectangleList,
  regularSquare,
  regularSquareCheck,
  regularStar,
  regularStarHalfStroke,
  regularTrashCan,
  solidArrowsRotate,
  solidBars,
  solidBolt,
  solidBuildingColumns,
  solidCalendarDays,
  solidChartLine,
  solidCommentDots,
  solidDollarSign,
  solidHouse,
  solidLocationDot,
  solidMoneyBill1,
  solidPaperPlane,
  solidSort,
  solidSortDown,
  solidSortUp,
  solidSquarePen,
  solidTrashCan,
  solidTriangleExclamation,
  solidUsers,
  solidUtensils,
  solidXmark
} from './internalIconsModule';

export const fontAwesomeFourNameToIcon: { [oldName: string]: Icon | void } = {
  bank: solidBuildingColumns,
  'building-o': regularBuilding,
  calendar: solidCalendarDays,
  'calendar-check-o': regularCalendarCheck,
  'check-circle-o': regularCircleCheck,
  'check-square-o': regularSquareCheck,
  'circle-o': regularCircle,
  'circle-thin': regularCircle,
  'clock-o': regularClock,
  close: solidXmark,
  'comment-o': regularComment,
  commenting: solidCommentDots,
  'commenting-o': regularCommentDots,
  cutlery: solidUtensils,
  diamond: regularGem,
  dollar: solidDollarSign,
  edit: regularPenToSquare,
  'facebook-square': brandsSquareFacebook,
  'file-archive-o': regularFileZipper,
  'file-audio-o': regularFileAudio,
  'file-excel-o': regularFileExcel,
  'file-image-o': regularFileImage,
  'file-o': regularFile,
  'file-pdf-o': regularFilePdf,
  'file-photo-o': regularFileImage,
  'file-picture-o': regularFileImage,
  'file-sound-o': regularFileAudio,
  'file-text-o': regularFileLines,
  'file-word-o': regularFileWord,
  'file-zip-o': regularFileZipper,
  'files-o': regularFiles,
  flash: solidBolt,
  'floppy-o': regularFloppyDisk,
  group: solidUsers,
  'heart-o': regularHeart,
  home: solidHouse,
  image: regularImage,
  institution: solidBuildingColumns,
  'line-chart': solidChartLine,
  'list-alt': regularRectangleList,
  'map-marker': solidLocationDot,
  money: solidMoneyBill1,
  navicon: solidBars,
  'pencil-square': solidSquarePen,
  'pencil-square-o': regularPenToSquare,
  photo: regularImage,
  'picture-o': regularImage,
  refresh: solidArrowsRotate,
  remove: solidXmark,
  reorder: solidBars,
  save: regularFloppyDisk,
  send: solidPaperPlane,
  'send-o': regularPaperPlane,
  'sort-asc': solidSortUp,
  'sort-desc': solidSortDown,
  'square-o': regularSquare,
  'star-half-empty': regularStarHalfStroke,
  'star-half-full': regularStarHalfStroke,
  'star-half-o': regularStarHalfStroke,
  'star-o': regularStar,
  'times-circle-o': regularCircleXmark,
  trash: solidTrashCan,
  'trash-o': regularTrashCan,
  'twitter-square': brandsSquareTwitter,
  unsorted: solidSort,
  usd: solidDollarSign,
  warning: solidTriangleExclamation,
  'youtube-play': brandsYoutube
};

// END_GENERATED_CODE
