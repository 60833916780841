// @flow

import * as React from 'react';
export type ErrorMessage = string;
export type Errors = { [string]: ErrorMessage, ... };

import scrollIntoView from 'scroll-into-view';

export type Validation = {|
  validator: string => boolean,
  errorMessage: string
|};
export type Validations = Map<string, Array<Validation> | Validation>;

export type InputValidationAction = {|
  type: 'INPUT_VALIDATION_COMPLETED',
  errorMessage: string | null,
  inputName: string
|};

export type FormValidationAction = {|
  type: 'FORM_VALIDATION_COMPLETED',
  errors: Errors
|};

function runValidation(
  inputName: string,
  validations: Validations
): string | null {
  let inputValidations = validations.get(inputName);
  if (!inputValidations) return null;

  const input = document.querySelector(`[name='${inputName.toString()}']`);
  if (
    !(
      input instanceof HTMLInputElement ||
      input instanceof HTMLSelectElement ||
      input instanceof HTMLTextAreaElement
    )
  ) {
    return null;
  }

  inputValidations = Array.isArray(inputValidations)
    ? inputValidations
    : [inputValidations];

  for (const validation of inputValidations) {
    if (!validation.validator(input.value)) {
      return validation.errorMessage;
    }
  }
  return null;
}

function runValidations(validations: Validations): Errors {
  const errors: { [string]: string } = {};
  validations.forEach((_, inputName) => {
    const error = runValidation(inputName, validations);
    if (error) {
      errors[inputName] = error;
    }
  });
  return errors;
}

type ValidationAction = InputValidationAction | FormValidationAction;

function errorReducer(state: Errors, action: ValidationAction): Errors {
  switch (action.type) {
    case 'INPUT_VALIDATION_COMPLETED':
      return { ...state, [action.inputName]: action.errorMessage };
    case 'FORM_VALIDATION_COMPLETED':
      return action.errors;
    default:
      return state;
  }
}

type ErrorReducerDispatch = (action: ValidationAction) => void;

function scrollFirstInvalidInputIntoView(errors: Errors) {
  const firstInvalidInput = document.querySelector(
    `[data-error='${Object.keys(errors)[0]}']`
  );
  scrollIntoView(firstInvalidInput, { align: { top: 0 } });
}

export function useErrorReducer(): [Errors, ErrorReducerDispatch] {
  return React.useReducer(errorReducer, {});
}

export function useFormValidations(): {|
  errors: Errors,
  submitFormValidation: $Call<typeof createSubmitFormValidation, any>,
  blurInputValidation: $Call<typeof createBlurInputValidation, any>,
  validateInputField: $Call<typeof createValidateInputField, any>
|} {
  const [errors, dispatch] = useErrorReducer();

  return {
    errors,
    submitFormValidation: createSubmitFormValidation(dispatch),
    blurInputValidation: createBlurInputValidation(dispatch),
    validateInputField: createValidateInputField(dispatch)
  };
}

function createValidateInputField(
  dispatch: ErrorReducerDispatch
): (inputName: string, validations: Validations) => void {
  return (inputName, validations) =>
    validateInputField(inputName, validations, dispatch);
}

function createSubmitFormValidation(
  dispatch: ErrorReducerDispatch
): (evt: SyntheticEvent<HTMLDivElement>, validations: Validations) => void {
  return (evt, validations) => submitFormValidation(evt, validations, dispatch);
}

function submitFormValidation(
  evt: SyntheticEvent<HTMLDivElement>,
  validations: Validations,
  dispatch: ErrorReducerDispatch
) {
  const allErrors = runValidations(validations);

  if (Object.keys(allErrors).length > 0) {
    evt.preventDefault();
    scrollFirstInvalidInputIntoView(allErrors);
  }

  dispatch({
    type: 'FORM_VALIDATION_COMPLETED',
    errors: allErrors
  });
}

function createBlurInputValidation(
  dispatch: ErrorReducerDispatch
): (target: SyntheticEvent<HTMLDivElement>, validations: Validations) => void {
  return (target, validations) =>
    blurInputValidation(target, validations, dispatch);
}

function blurInputValidation(
  target: SyntheticEvent<HTMLDivElement>,
  validations: Validations,
  dispatch: ErrorReducerDispatch
) {
  if (
    (target instanceof HTMLInputElement ||
      target instanceof HTMLSelectElement ||
      target instanceof HTMLTextAreaElement) &&
    validations.get(target.name)
  ) {
    validateInputField(target.name, validations, dispatch);
  } else if (
    // TODO: Fix how we are validating the time inputs
    // We are currently targing the div, because we can't
    // target the hidden input
    target instanceof HTMLDivElement &&
    validations.get(target.dataset.name)
  ) {
    validateInputField(target.dataset.name, validations, dispatch);
  }
}

function validateInputField(
  inputName: string,
  validations: Validations,
  dispatch: ErrorReducerDispatch
) {
  const errorMessage = runValidation(inputName, validations);
  dispatch({
    type: 'INPUT_VALIDATION_COMPLETED',
    inputName: inputName,
    errorMessage: errorMessage
  });
}
