// @flow

import * as React from 'react';

import FontAwesomeIcon from 'common/components/FontAwesomeIcon';
import impossible from 'common/types/impossible';

type Props = {
  isOpen: boolean,
  icon?: 'chevron' | 'caret',
  ...
};

export default function AccordionStateIcon(props: Props): React.Node {
  const icon: 'chevron' | 'caret' = props.icon || 'chevron';
  switch (icon) {
    case 'chevron':
      if (props.isOpen) {
        return (
          <FontAwesomeIcon icon={{ legacyClassName: 'fa fa-chevron-up' }} />
        );
      }
      return (
        <FontAwesomeIcon icon={{ legacyClassName: 'fa fa-chevron-down' }} />
      );
    case 'caret':
      if (props.isOpen) {
        return <FontAwesomeIcon icon={{ legacyClassName: 'fa fa-caret-up' }} />;
      }
      return <FontAwesomeIcon icon={{ legacyClassName: 'fa fa-caret-down' }} />;
    default:
      return impossible(icon);
  }
}
