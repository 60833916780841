// @flow

opaque type IconFamily: string = 'light' | 'regular' | 'solid' | 'brands';
export opaque type Icon: [IconFamily, string] = [IconFamily, string];

export function maybeIcon(value: mixed): Icon | null {
  if (!Array.isArray(value)) {
    return null;
  }
  if (value.length !== 2) {
    return null;
  }
  const [iconFamily, iconName] = value;
  if (
    (iconFamily !== 'light' &&
      iconFamily !== 'regular' &&
      iconFamily !== 'solid' &&
      iconFamily !== 'brands') ||
    typeof iconName !== 'string'
  ) {
    return null;
  }

  return [iconFamily, iconName];
}

export function assertIcon(value: mixed): Icon {
  const icon = maybeIcon(value);
  if (!icon) {
    const jsonValue = JSON.stringify(value);
    throw new Error(
      `Not an icon: ${jsonValue === undefined ? 'undefined' : jsonValue}`
    );
  }
  return icon;
}

// START_GENERATED_CODE
// Do not make manual edits here, they will be overwritten!
// Recreate this content by running:
//
//     just deps generate-font-awesome-v6-icons
//

// Light icons
export const lightBolt: Icon = ['light', 'bolt'];
export const lightBriefcase: Icon = ['light', 'briefcase'];
export const lightCakeCandles: Icon = ['light', 'cake-candles'];
export const lightCalendarDays: Icon = ['light', 'calendar-days'];
export const lightCheck: Icon = ['light', 'check'];
export const lightCircleXmark: Icon = ['light', 'circle-xmark'];
export const lightClock: Icon = ['light', 'clock'];
export const lightCoins: Icon = ['light', 'coins'];
export const lightEnvelope: Icon = ['light', 'envelope'];
export const lightHandshake: Icon = ['light', 'handshake'];
export const lightHouse: Icon = ['light', 'house'];
export const lightPhoneFlip: Icon = ['light', 'phone-flip'];
export const lightPiggyBank: Icon = ['light', 'piggy-bank'];
export const lightStar: Icon = ['light', 'star'];
export const lightTag: Icon = ['light', 'tag'];
export const lightTags: Icon = ['light', 'tags'];
export const lightTrashCan: Icon = ['light', 'trash-can'];
export const lightUser: Icon = ['light', 'user'];
export const lightUserGroup: Icon = ['light', 'user-group'];
export const lightXmark: Icon = ['light', 'xmark'];

// Regular icons
export const regularArrowUpRightFromSquare: Icon = [
  'regular',
  'arrow-up-right-from-square'
];
export const regularBuilding: Icon = ['regular', 'building'];
export const regularCalendarCheck: Icon = ['regular', 'calendar-check'];
export const regularCheck: Icon = ['regular', 'check'];
export const regularCircle: Icon = ['regular', 'circle'];
export const regularCircleCheck: Icon = ['regular', 'circle-check'];
export const regularCircleXmark: Icon = ['regular', 'circle-xmark'];
export const regularClock: Icon = ['regular', 'clock'];
export const regularComment: Icon = ['regular', 'comment'];
export const regularCommentDots: Icon = ['regular', 'comment-dots'];
export const regularDownload: Icon = ['regular', 'download'];
export const regularFile: Icon = ['regular', 'file'];
export const regularFileAudio: Icon = ['regular', 'file-audio'];
export const regularFileExcel: Icon = ['regular', 'file-excel'];
export const regularFileImage: Icon = ['regular', 'file-image'];
export const regularFileLines: Icon = ['regular', 'file-lines'];
export const regularFilePdf: Icon = ['regular', 'file-pdf'];
export const regularFileWord: Icon = ['regular', 'file-word'];
export const regularFileZipper: Icon = ['regular', 'file-zipper'];
export const regularFiles: Icon = ['regular', 'files'];
export const regularFilm: Icon = ['regular', 'film'];
export const regularFloppyDisk: Icon = ['regular', 'floppy-disk'];
export const regularGem: Icon = ['regular', 'gem'];
export const regularHeart: Icon = ['regular', 'heart'];
export const regularImage: Icon = ['regular', 'image'];
export const regularList: Icon = ['regular', 'list'];
export const regularMagnifyingGlass: Icon = ['regular', 'magnifying-glass'];
export const regularMinus: Icon = ['regular', 'minus'];
export const regularMoneyBill1: Icon = ['regular', 'money-bill-1'];
export const regularPaperPlane: Icon = ['regular', 'paper-plane'];
export const regularPenToSquare: Icon = ['regular', 'pen-to-square'];
export const regularPlus: Icon = ['regular', 'plus'];
export const regularRectangleList: Icon = ['regular', 'rectangle-list'];
export const regularSpinner: Icon = ['regular', 'spinner'];
export const regularSquare: Icon = ['regular', 'square'];
export const regularSquareCheck: Icon = ['regular', 'square-check'];
export const regularStar: Icon = ['regular', 'star'];
export const regularStarHalfStroke: Icon = ['regular', 'star-half-stroke'];
export const regularTrashCan: Icon = ['regular', 'trash-can'];
export const regularTriangleExclamation: Icon = [
  'regular',
  'triangle-exclamation'
];

// Solid icons
export const solidAngleDown: Icon = ['solid', 'angle-down'];
export const solidAngleLeft: Icon = ['solid', 'angle-left'];
export const solidAngleRight: Icon = ['solid', 'angle-right'];
export const solidAngleUp: Icon = ['solid', 'angle-up'];
export const solidArrowDown: Icon = ['solid', 'arrow-down'];
export const solidArrowsRotate: Icon = ['solid', 'arrows-rotate'];
export const solidAt: Icon = ['solid', 'at'];
export const solidBars: Icon = ['solid', 'bars'];
export const solidBolt: Icon = ['solid', 'bolt'];
export const solidBomb: Icon = ['solid', 'bomb'];
export const solidBookmark: Icon = ['solid', 'bookmark'];
export const solidBriefcase: Icon = ['solid', 'briefcase'];
export const solidBuilding: Icon = ['solid', 'building'];
export const solidBuildingColumns: Icon = ['solid', 'building-columns'];
export const solidBullhorn: Icon = ['solid', 'bullhorn'];
export const solidCalculator: Icon = ['solid', 'calculator'];
export const solidCalendarDays: Icon = ['solid', 'calendar-days'];
export const solidCaretDown: Icon = ['solid', 'caret-down'];
export const solidCaretLeft: Icon = ['solid', 'caret-left'];
export const solidCaretRight: Icon = ['solid', 'caret-right'];
export const solidCaretUp: Icon = ['solid', 'caret-up'];
export const solidChartBar: Icon = ['solid', 'chart-bar'];
export const solidChartLine: Icon = ['solid', 'chart-line'];
export const solidCheck: Icon = ['solid', 'check'];
export const solidChevronDown: Icon = ['solid', 'chevron-down'];
export const solidChevronLeft: Icon = ['solid', 'chevron-left'];
export const solidChevronRight: Icon = ['solid', 'chevron-right'];
export const solidChevronUp: Icon = ['solid', 'chevron-up'];
export const solidCircleCheck: Icon = ['solid', 'circle-check'];
export const solidCircleExclamation: Icon = ['solid', 'circle-exclamation'];
export const solidCircleInfo: Icon = ['solid', 'circle-info'];
export const solidCirclePlus: Icon = ['solid', 'circle-plus'];
export const solidCircleQuestion: Icon = ['solid', 'circle-question'];
export const solidCircleXmark: Icon = ['solid', 'circle-xmark'];
export const solidComment: Icon = ['solid', 'comment'];
export const solidCommentDots: Icon = ['solid', 'comment-dots'];
export const solidComments: Icon = ['solid', 'comments'];
export const solidDollarSign: Icon = ['solid', 'dollar-sign'];
export const solidDownload: Icon = ['solid', 'download'];
export const solidEnvelope: Icon = ['solid', 'envelope'];
export const solidEye: Icon = ['solid', 'eye'];
export const solidEyeSlash: Icon = ['solid', 'eye-slash'];
export const solidFilm: Icon = ['solid', 'film'];
export const solidGift: Icon = ['solid', 'gift'];
export const solidGlobe: Icon = ['solid', 'globe'];
export const solidHatChef: Icon = ['solid', 'hat-chef'];
export const solidHeart: Icon = ['solid', 'heart'];
export const solidHouse: Icon = ['solid', 'house'];
export const solidIndustry: Icon = ['solid', 'industry'];
export const solidLanguage: Icon = ['solid', 'language'];
export const solidList: Icon = ['solid', 'list'];
export const solidListUl: Icon = ['solid', 'list-ul'];
export const solidLocationDot: Icon = ['solid', 'location-dot'];
export const solidLocationPin: Icon = ['solid', 'location-pin'];
export const solidLock: Icon = ['solid', 'lock'];
export const solidMagnifyingGlass: Icon = ['solid', 'magnifying-glass'];
export const solidMoneyBill1: Icon = ['solid', 'money-bill-1'];
export const solidObjectGroup: Icon = ['solid', 'object-group'];
export const solidPaperPlane: Icon = ['solid', 'paper-plane'];
export const solidPaperclip: Icon = ['solid', 'paperclip'];
export const solidPenToSquare: Icon = ['solid', 'pen-to-square'];
export const solidPerson: Icon = ['solid', 'person'];
export const solidPhone: Icon = ['solid', 'phone'];
export const solidPlus: Icon = ['solid', 'plus'];
export const solidQuestion: Icon = ['solid', 'question'];
export const solidRocket: Icon = ['solid', 'rocket'];
export const solidSliders: Icon = ['solid', 'sliders'];
export const solidSort: Icon = ['solid', 'sort'];
export const solidSortDown: Icon = ['solid', 'sort-down'];
export const solidSortUp: Icon = ['solid', 'sort-up'];
export const solidSpinner: Icon = ['solid', 'spinner'];
export const solidSquareCheck: Icon = ['solid', 'square-check'];
export const solidSquarePen: Icon = ['solid', 'square-pen'];
export const solidStar: Icon = ['solid', 'star'];
export const solidStreetView: Icon = ['solid', 'street-view'];
export const solidTag: Icon = ['solid', 'tag'];
export const solidTags: Icon = ['solid', 'tags'];
export const solidTrashCan: Icon = ['solid', 'trash-can'];
export const solidTriangleExclamation: Icon = ['solid', 'triangle-exclamation'];
export const solidUpload: Icon = ['solid', 'upload'];
export const solidUser: Icon = ['solid', 'user'];
export const solidUsers: Icon = ['solid', 'users'];
export const solidUtensils: Icon = ['solid', 'utensils'];
export const solidWrench: Icon = ['solid', 'wrench'];
export const solidXmark: Icon = ['solid', 'xmark'];

// Brands icons
export const brandsSquareFacebook: Icon = ['brands', 'square-facebook'];
export const brandsSquareTwitter: Icon = ['brands', 'square-twitter'];
export const brandsYoutube: Icon = ['brands', 'youtube'];

// END_GENERATED_CODE

// Old aliases. TODO: Remove these and use proper names instead
export const lightBirthdayCake = lightCakeCandles;
export const lightCalendarAlt = lightCalendarDays;
export const lightHome = lightHouse;
export const lightPhone = lightPhoneFlip;
export const lightTimesCircle = lightCircleXmark;
export const lightTrash = lightTrashCan;
export const lightUserFriends = lightUserGroup;
export const regularEdit = regularPenToSquare;
export const regularExclamationTriangle = regularTriangleExclamation;
export const regularExternalLink = regularArrowUpRightFromSquare;
export const regularSearch = regularMagnifyingGlass;
export const solidCalendarAlt = solidCalendarDays;
export const solidCheckSquare = solidSquareCheck;
export const solidExclamationTriangle = solidTriangleExclamation;
export const solidMapMarker = solidLocationPin;
export const solidSearch = solidMagnifyingGlass;
