// @flow

import * as React from 'react';
import cx from 'classnames';
import styles from './InputErrorWrapperNew.scss';

import FontAwesomeIcon, {
  regularExclamationTriangle
} from 'common/components/FontAwesomeIcon';

type InputErrorWrapperProps = {|
  message: string,
  children: React.Node,
  errorId: string,
  customIconPosition?: string,
  useErrorBorder?: boolean
|};
export default function InputErrorWrapperNew({
  message,
  children,
  errorId,
  customIconPosition,
  useErrorBorder
}: InputErrorWrapperProps): React.Node {
  return (
    <div
      className={cx({
        [styles.errorContainer]: true,
        [styles.isError]: message,
        [styles.fullBorder]: message && useErrorBorder
      })}
      data-error={errorId}
    >
      {children}
      {message && (
        <>
          <div
            className={styles.invalidFieldIndicator}
            style={{ right: customIconPosition }}
          >
            <FontAwesomeIcon icon={regularExclamationTriangle} />
          </div>
          <div className={styles.errorMessage}>
            <span>{message}</span>
          </div>
        </>
      )}
    </div>
  );
}
