// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';
import ReactDatePicker from 'react-date-picker';
import moment from 'moment';
import { usePopper } from 'react-popper';
import type { ModifierArguments } from '@popperjs/core';

import FontAwesomeIcon, {
  solidCalendarAlt
} from 'common/components/FontAwesomeIcon';
import type { DateString } from 'common/json/extract';

import styles from './DateInput.scss';

// From https://popper.js.org/docs/v2/modifiers/community-modifiers/
const sameWidth = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }: ModifierArguments<any>) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }: ModifierArguments<any>) => {
    if (state.elements.reference instanceof HTMLElement) {
      state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
    }
  }
};

type DateInputProps = {|
  inputId: string,
  inputName: string,
  inputClassName?: string,
  initialValue?: null | DateString<'YYYY-MM-DD'>,
  readOnly?: boolean,
  required?: boolean,
  calendarType: 'disable-past-days' | 'show-years' | 'show-decades',
  validator?: () => mixed
|};
export default function DateInput({
  inputId,
  inputName,
  inputClassName,
  initialValue = null,
  readOnly = false,
  required = false,
  calendarType,
  validator
}: DateInputProps): React.Node {
  const [needsValidation, setNeedsValidation] = React.useState(
    initialValue !== null
  );
  const [referenceElement, setReferenceElement] =
    React.useState<null | HTMLDivElement>(null);
  const [popperElement, setPopperElement] =
    React.useState<null | HTMLDivElement>(null);
  const [state, dispatch] = useDateReducer(initialValue);
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    { placement: 'bottom', modifiers: [sameWidth] }
  );
  const onHide = React.useCallback(() => {
    setNeedsValidation(true);
  }, []);
  const {
    hideCalendar,
    showCalendar,
    isDatePickerVisible,
    setCalendarWrapperRef,
    setCalendarRef
  } = useCalendarVisibility({
    onHide
  });

  React.useEffect(() => {
    if (needsValidation) {
      validator && validator();
      setNeedsValidation(false);
    }
  }, [needsValidation, validator]);

  const [popperContainer, setPopperContainer] =
    React.useState<null | HTMLElement>(null);
  React.useEffect(() => {
    setPopperContainer(document.querySelector('#popper-container'));
  }, []);

  return (
    <>
      <div ref={setCalendarWrapperRef}>
        <div style={{ position: 'relative' }} ref={setReferenceElement}>
          <input
            type="text"
            className={inputClassName}
            // Use an empty placeholder to allow targeting styles based on the
            // emptiness of the text input element itself
            placeholder=" "
            id={inputId}
            autoComplete="off"
            readOnly={readOnly}
            onFocus={showCalendar}
            onClick={showCalendar}
            required={required}
            value={state.inputValue}
            onKeyDown={(evt: KeyboardEvent) => {
              // Prevent implicit form submission when calendar is open and Enter is pressed:
              if (isDatePickerVisible && evt.key === 'Enter') {
                evt.preventDefault();
                hideCalendar();
              }
            }}
            onChange={evt => {
              const writtenDate = evt.currentTarget.value;
              dispatch({
                type: 'INPUT_CHANGED',
                writtenDate
              });
            }}
          />
          <input type="hidden" name={inputName} value={state.dateValue || ''} />
          <FontAwesomeIcon
            icon={solidCalendarAlt}
            className={styles.calendarIcon}
          />
        </div>
      </div>
      {isDatePickerVisible &&
        popperContainer !== null &&
        ReactDOM.createPortal(
          <div
            {...popperAttributes.popper}
            ref={node => {
              setPopperElement(node);
              setCalendarRef(node);
            }}
            style={{
              ...popperStyles.popper,
              // Render on top of other elements by default
              zIndex: 999999
            }}
          >
            <div
              className={styles.datePickerContainer}
              data-testid="calendar-container"
            >
              <DatePicker
                allowPastDays={calendarType !== 'disable-past-days'}
                showDecades={calendarType === 'show-decades'}
                readOnly={readOnly}
                onChange={d => {
                  dispatch({
                    type: 'CALENDAR_DATE_CHOSEN',
                    selectedDate: d
                  });
                  hideCalendar();
                }}
                viewDate={state.calendarViewDate}
                onViewDateChange={d => {
                  dispatch({
                    type: 'CALENDAR_VIEW_DATE_CHANGED',
                    viewDate: d
                  });
                }}
                selectedDate={state.dateValue}
              />
            </div>
          </div>,
          popperContainer
        )}
    </>
  );
}

function DatePicker({
  allowPastDays,
  showDecades,
  onChange,
  readOnly,
  viewDate,
  onViewDateChange,
  selectedDate
}: {|
  allowPastDays: boolean,
  showDecades: boolean,
  onChange: (d: string) => void,
  readOnly: boolean,
  onViewDateChange: (d: string) => void,
  selectedDate: null | string,
  viewDate: null | string
|}) {
  const viewOrder = ['month', 'year'];
  if (showDecades) viewOrder.push('decade');
  const [minDate, maxDate] = (() => {
    // react-date-picker uses local-offset moments, so we should use them here, too
    /* eslint-disable rulesdir/no-plain-moment */
    if (readOnly) {
      if (selectedDate) {
        // The selected date should be selectable as it will close the calendar
        // and it also looks more legible when it isn't appearing as disabled.
        return [
          moment(selectedDate).startOf('day'),
          moment(selectedDate).startOf('day')
        ];
      }
      // Show as if no days are selectable by having minDate after maxDate
      return [moment().add(1, 'day').startOf('day'), moment().startOf('day')];
    }

    if (allowPastDays) {
      // We want to allow selecting any historical day as well as selecting any
      // future day.
      return [null, null];
    }

    // We disallow selecting a historical day but don't limit the future
    return [moment().startOf('day'), null];
    /* eslint-enable rulesdir/no-plain-moment */
  })();
  return (
    <div className={styles.datePicker}>
      <ReactDatePicker
        minDate={minDate}
        maxDate={maxDate}
        viewOrder={viewOrder}
        hideFooter
        onChange={onChange}
        date={selectedDate}
        viewDate={viewDate}
        onViewDateChange={onViewDateChange}
      />
    </div>
  );
}

type DateReducerState = {|
  calendarViewDate: null | string,
  inputValue: string,
  dateValue: null | string
|};

type CalendarDateChosenAction = {|
  type: 'CALENDAR_DATE_CHOSEN',
  selectedDate: string
|};
type CalendarViewDateChangedAction = {|
  type: 'CALENDAR_VIEW_DATE_CHANGED',
  viewDate: string
|};
type InputChangedAction = {|
  type: 'INPUT_CHANGED',
  writtenDate: string
|};

type DateAction =
  | CalendarDateChosenAction
  | CalendarViewDateChangedAction
  | InputChangedAction;
function dateReducer(state: DateReducerState, action: DateAction) {
  switch (action.type) {
    case 'CALENDAR_DATE_CHOSEN': {
      const mom = moment.utc(action.selectedDate, 'YYYY-MM-DD', true);
      return {
        calendarViewDate: mom.format('YYYY-MM-DD'),
        inputValue: mom.format('l'),
        dateValue: mom.format('YYYY-MM-DD')
      };
    }
    case 'CALENDAR_VIEW_DATE_CHANGED': {
      const mom = moment.utc(action.viewDate, 'YYYY-MM-DD', true);
      return {
        ...state,
        calendarViewDate: mom.format('YYYY-MM-DD')
      };
    }
    case 'INPUT_CHANGED': {
      const mom = moment.utc(action.writtenDate, 'l', true);
      if (mom.isValid()) {
        return {
          calendarViewDate: mom.format('YYYY-MM-DD'),
          inputValue: action.writtenDate,
          dateValue: mom.format('YYYY-MM-DD')
        };
      } else {
        return {
          calendarViewDate: state.calendarViewDate,
          inputValue: action.writtenDate,
          dateValue: null
        };
      }
    }
    default:
      return state;
  }
}
type DateReducerDispatch = (action: DateAction) => mixed;

function useDateReducer(
  initialDateValue: null | DateString<'YYYY-MM-DD'>
): [DateReducerState, DateReducerDispatch] {
  const initialValue: DateReducerState = initialDateValue
    ? (() => {
        const mom = moment.utc(initialDateValue, 'YYYY-MM-DD', true);
        return {
          calendarViewDate: mom.format('YYYY-MM-DD'),
          inputValue: mom.format('l'),
          dateValue: mom.format('YYYY-MM-DD')
        };
      })()
    : {
        calendarViewDate: null,
        inputValue: '',
        dateValue: null
      };

  const [state, dispatch] = React.useReducer(dateReducer, initialValue);
  return [state, dispatch];
}

function useCalendarVisibility({ onHide }: {| onHide: () => void |}) {
  const [isDatePickerVisible, setDatePickerVisible] = React.useState(false);
  const dateInputWrapperRef = React.useRef<null | HTMLElement>(null);
  const calendarRef = React.useRef<null | HTMLElement>(null);

  const hideCalendar = React.useCallback(() => {
    setDatePickerVisible(false);
    onHide();
  }, [onHide]);

  function showCalendar() {
    setDatePickerVisible(true);
  }

  function setCalendarWrapperRef(node: HTMLElement | null) {
    dateInputWrapperRef.current = node;
  }

  function setCalendarRef(node: HTMLElement | null) {
    calendarRef.current = node;
  }

  React.useLayoutEffect(() => {
    if (!isDatePickerVisible) return () => {};
    const globalListener = (evt: MouseEvent | FocusEvent) => {
      // Do an early return if date input wrapper or calendar wrapper
      // is not in the DOM. It could happen if the component is unmounting.
      const dateInputWrapper = dateInputWrapperRef.current;
      const calendarWrapper = calendarRef.current;
      if (!dateInputWrapper) return;
      if (!calendarWrapper) return;

      // Now get the target of the click or focusin event to a variable
      // so that we can tell Flow we have a valid Node here.
      const eventTarget = evt.target;
      if (!(eventTarget instanceof Node)) return;

      // Check if click or focusin targets the date input field wrapper.
      // If it did, we don't want to close the calendar yet.
      if (dateInputWrapper.contains(eventTarget)) return;

      // Now we need to also check whether the click or focusin event
      // happened inside the calendar container. If it did, then
      // we don't want to close the calendar as user is interacting with it.
      if (calendarWrapper.contains(eventTarget)) return;

      // If we reach this point, it means that the focusin or click
      // happened somewhere which should hide the calendar.
      hideCalendar();
    };
    // Use capture-phase listeners so that these listeners get called before
    // the event itself trickles down to the target element.
    //
    // If we didn't use a capture-phase listener, then these event listeners
    // would be called during the bubbling phase. At that point, we might be
    // too late: React has had a chance on reacting to the events already and
    // can have changed the DOM underneath us.
    //
    // A good way to understand the difference between capture and bubble phase
    // is this tool: https://domevents.dev/
    //
    // From the MDN docs:
    // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#parameters
    //
    // * `capture`: A boolean value indicating that events of this type will be
    //   dispatched to the registered listener before being dispatched to any
    //   EventTarget beneath it in the DOM tree.
    document.addEventListener('click', globalListener, { capture: true });
    document.addEventListener('focusin', globalListener, { capture: true });
    return () => {
      document.removeEventListener('click', globalListener, { capture: true });
      document.removeEventListener('focusin', globalListener, {
        capture: true
      });
    };
  }, [hideCalendar, isDatePickerVisible]);

  React.useLayoutEffect(() => {
    if (!isDatePickerVisible) return () => {};
    const globalListener = (evt: KeyboardEvent) => {
      if (evt.key === 'Escape') {
        hideCalendar();
      }
    };
    document.addEventListener('keydown', globalListener);
    return () => document.removeEventListener('keydown', globalListener);
  }, [hideCalendar, isDatePickerVisible]);

  return {
    isDatePickerVisible,
    hideCalendar,
    showCalendar,
    setCalendarWrapperRef,
    setCalendarRef
  };
}
