// @flow

import * as React from 'react';
import times from 'lodash/times';
import FontAwesomeIcon from 'common/components/FontAwesomeIcon';

type Props = {|
  score: number,
  max: number
|};

class Rating extends React.Component<Props> {
  static defaultProps: {| max: number, score: number |} = {
    score: 0,
    max: 5
  };

  render(): React.Node {
    const score = this.props.score;
    const max = this.props.max;

    const fraction = score - Math.floor(score);

    const fullStarCount = Math.floor(score);
    const emptyStarCount = max - Math.ceil(score);

    const partialStar = (function () {
      if (fraction === 0) {
        return null;
      } else if (fraction <= 0.25) {
        return (
          <FontAwesomeIcon
            icon={{ legacyClassName: 'fa fa-star-o' }}
            className="b-rating__star b-rating__star--empty"
          />
        );
      } else if (fraction <= 0.75) {
        return (
          <FontAwesomeIcon
            icon={{ legacyClassName: 'fa fa-star-half-o' }}
            className="b-rating__star b-rating__star--half"
          />
        );
      } else {
        return (
          <FontAwesomeIcon
            icon={{ legacyClassName: 'fa fa-star' }}
            className="b-rating__star b-rating__star--full"
          />
        );
      }
    })();

    return (
      <div className="b-rating">
        {times(fullStarCount, index => {
          return (
            <FontAwesomeIcon
              icon={{ legacyClassName: 'fa fa-star' }}
              className="b-rating__star b-rating__star--full"
              key={`star-full:${index}`}
            />
          );
        })}
        {partialStar}
        {times(emptyStarCount, index => {
          return (
            <FontAwesomeIcon
              icon={{ legacyClassName: 'fa fa-star-o' }}
              className="b-rating__star b-rating__star--empty"
              key={`star-empty:${index}`}
            />
          );
        })}
      </div>
    );
  }
}

export default Rating;
