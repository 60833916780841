// @flow
import * as React from 'react';
import cx from 'classnames';

import LegacyIcon from './fontAwesomeIconInternalModules/LegacyIcon';
import { modifierClassName } from './fontAwesomeIconInternalModules/modifiers';
import {
  type Icon,
  assertIcon
} from './fontAwesomeIconInternalModules/internalIconsModule';
export * from './fontAwesomeIconInternalModules/internalIconsModule';

export type Props = {
  // Enforce that `name` prop is _not_ given as that was only used for old
  // FAIcon times and would signal that some code change was broken.
  name?: empty,
  className?: string,
  icon:
    | Icon
    | {|
        legacyClassName: string
      |},
  fixedWidth?: boolean,
  spin?: boolean,
  pulse?: boolean,
  ...
};

export default function FontAwesomeIcon(props: Props): React.Node {
  const { icon, ...otherProps } = props;
  if (icon && typeof icon === 'object' && icon.legacyClassName) {
    return (
      <LegacyIcon
        {...otherProps}
        icon={{
          legacyClassName: icon.legacyClassName
        }}
      />
    );
  }

  const [iconFamily, iconName] = assertIcon(icon);
  const { className, spin, fixedWidth, pulse, ...passThroughProps } =
    otherProps;
  const classNameForIcon = cx(
    `fa-${iconFamily}`,
    `fa-${iconName}`,
    {
      [modifierClassName('fw')]: fixedWidth,
      [modifierClassName('spin')]: spin,
      [modifierClassName('spin-pulse')]: pulse
    },
    className
  );
  // eslint-disable-next-line rulesdir/no-plain-icons -- This is the safe call.
  return <i {...passThroughProps} className={classNameForIcon} />;
}

export type StackProps = {|
  foregroundIcon: Icon,
  backgroundIcon: Icon
|};

export function FontAwesomeIconStack(props: StackProps): React.Node {
  return (
    <span className="fa-stack">
      <FontAwesomeIcon
        icon={props.backgroundIcon}
        className={modifierClassName('stack-2x')}
      />
      <FontAwesomeIcon
        icon={props.foregroundIcon}
        className={modifierClassName('stack-1x')}
      />
    </span>
  );
}
