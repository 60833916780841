// @flow

// START_GENERATED_CODE
// Do not make manual edits here, they will be overwritten!
// Recreate this content by running:
//
//     just deps generate-font-awesome-v6-icons
//

import {
  solidAngleDown,
  solidAngleLeft,
  solidAngleRight,
  solidAngleUp,
  solidArrowDown,
  solidArrowsRotate,
  solidAt,
  solidBars,
  solidBolt,
  solidBomb,
  solidBookmark,
  solidBriefcase,
  solidBuilding,
  solidBuildingColumns,
  solidBullhorn,
  solidCalculator,
  solidCalendarDays,
  solidCaretDown,
  solidCaretLeft,
  solidCaretRight,
  solidCaretUp,
  solidChartBar,
  solidChartLine,
  solidCheck,
  solidChevronDown,
  solidChevronLeft,
  solidChevronRight,
  solidChevronUp,
  solidCircleCheck,
  solidCircleExclamation,
  solidCircleInfo,
  solidCirclePlus,
  solidCircleQuestion,
  solidCircleXmark,
  solidComment,
  solidCommentDots,
  solidComments,
  solidDollarSign,
  solidDownload,
  solidEnvelope,
  solidEye,
  solidEyeSlash,
  solidFilm,
  solidGift,
  solidGlobe,
  solidHatChef,
  solidHeart,
  solidHouse,
  solidIndustry,
  solidLanguage,
  solidList,
  solidListUl,
  solidLocationDot,
  solidLocationPin,
  solidLock,
  solidMagnifyingGlass,
  solidMoneyBill1,
  solidObjectGroup,
  solidPaperPlane,
  solidPaperclip,
  solidPenToSquare,
  solidPerson,
  solidPhone,
  solidPlus,
  solidQuestion,
  solidRocket,
  solidSliders,
  solidSort,
  solidSortDown,
  solidSortUp,
  solidSpinner,
  solidSquareCheck,
  solidSquarePen,
  solidStar,
  solidStreetView,
  solidTag,
  solidTags,
  solidTrashCan,
  solidTriangleExclamation,
  solidUpload,
  solidUser,
  solidUsers,
  solidUtensils,
  solidWrench,
  solidXmark
} from './internalIconsModule';

export const allSolidIcons = [
  solidAngleDown,
  solidAngleLeft,
  solidAngleRight,
  solidAngleUp,
  solidArrowDown,
  solidArrowsRotate,
  solidAt,
  solidBars,
  solidBolt,
  solidBomb,
  solidBookmark,
  solidBriefcase,
  solidBuilding,
  solidBuildingColumns,
  solidBullhorn,
  solidCalculator,
  solidCalendarDays,
  solidCaretDown,
  solidCaretLeft,
  solidCaretRight,
  solidCaretUp,
  solidChartBar,
  solidChartLine,
  solidCheck,
  solidChevronDown,
  solidChevronLeft,
  solidChevronRight,
  solidChevronUp,
  solidCircleCheck,
  solidCircleExclamation,
  solidCircleInfo,
  solidCirclePlus,
  solidCircleQuestion,
  solidCircleXmark,
  solidComment,
  solidCommentDots,
  solidComments,
  solidDollarSign,
  solidDownload,
  solidEnvelope,
  solidEye,
  solidEyeSlash,
  solidFilm,
  solidGift,
  solidGlobe,
  solidHatChef,
  solidHeart,
  solidHouse,
  solidIndustry,
  solidLanguage,
  solidList,
  solidListUl,
  solidLocationDot,
  solidLocationPin,
  solidLock,
  solidMagnifyingGlass,
  solidMoneyBill1,
  solidObjectGroup,
  solidPaperPlane,
  solidPaperclip,
  solidPenToSquare,
  solidPerson,
  solidPhone,
  solidPlus,
  solidQuestion,
  solidRocket,
  solidSliders,
  solidSort,
  solidSortDown,
  solidSortUp,
  solidSpinner,
  solidSquareCheck,
  solidSquarePen,
  solidStar,
  solidStreetView,
  solidTag,
  solidTags,
  solidTrashCan,
  solidTriangleExclamation,
  solidUpload,
  solidUser,
  solidUsers,
  solidUtensils,
  solidWrench,
  solidXmark
];

// END_GENERATED_CODE
