// @flow

// START_GENERATED_CODE
// Do not make manual edits here, they will be overwritten!
// Recreate this content by running:
//
//     just deps generate-font-awesome-v6-icons
//

import {
  type Icon,
  solidArrowsRotate,
  solidBars,
  solidBolt,
  solidBuildingColumns,
  solidCalendarDays,
  solidChartBar,
  solidChartLine,
  solidCircleCheck,
  solidCircleExclamation,
  solidCircleInfo,
  solidCirclePlus,
  solidCircleQuestion,
  solidCircleXmark,
  solidCommentDots,
  solidDollarSign,
  solidHouse,
  solidList,
  solidListUl,
  solidLocationDot,
  solidLocationPin,
  solidMagnifyingGlass,
  solidMoneyBill1,
  solidPenToSquare,
  solidPerson,
  solidPlus,
  solidSliders,
  solidSort,
  solidSortDown,
  solidSortUp,
  solidSquareCheck,
  solidSquarePen,
  solidTrashCan,
  solidTriangleExclamation,
  solidUtensils,
  solidXmark
} from './internalIconsModule';

export const solidIconAliases: { [oldName: string]: Icon | void } = {
  add: solidPlus,
  bank: solidBuildingColumns,
  'bar-chart': solidChartBar,
  'calendar-alt': solidCalendarDays,
  'check-circle': solidCircleCheck,
  'check-square': solidSquareCheck,
  close: solidXmark,
  commenting: solidCommentDots,
  cutlery: solidUtensils,
  dollar: solidDollarSign,
  edit: solidPenToSquare,
  'exclamation-circle': solidCircleExclamation,
  'exclamation-triangle': solidTriangleExclamation,
  home: solidHouse,
  'home-alt': solidHouse,
  'home-lg-alt': solidHouse,
  'info-circle': solidCircleInfo,
  institution: solidBuildingColumns,
  'line-chart': solidChartLine,
  'list-dots': solidListUl,
  'list-squares': solidList,
  male: solidPerson,
  'map-marker': solidLocationPin,
  'map-marker-alt': solidLocationDot,
  'money-bill-alt': solidMoneyBill1,
  multiply: solidXmark,
  museum: solidBuildingColumns,
  navicon: solidBars,
  'pen-square': solidSquarePen,
  'pencil-square': solidSquarePen,
  'plus-circle': solidCirclePlus,
  'question-circle': solidCircleQuestion,
  refresh: solidArrowsRotate,
  remove: solidXmark,
  search: solidMagnifyingGlass,
  'sliders-h': solidSliders,
  'sort-asc': solidSortUp,
  'sort-desc': solidSortDown,
  sync: solidArrowsRotate,
  times: solidXmark,
  'times-circle': solidCircleXmark,
  'trash-alt': solidTrashCan,
  university: solidBuildingColumns,
  unsorted: solidSort,
  usd: solidDollarSign,
  warning: solidTriangleExclamation,
  'xmark-circle': solidCircleXmark,
  zap: solidBolt
};

// END_GENERATED_CODE
