// @flow

import * as React from 'react';
import whatInput from 'what-input';
import classNames from 'classnames';

import { uuid4 } from 'sumi-hash';
import type { UUID } from 'sumi-hash';

import styles from './accordion/Accordion.scss';

type Heading = (param: {
  isOpen: boolean,
  ...
}) => React.Element<any> | Array<React.Element<any>>;

export type Props = {|
  heading: Heading,
  body: React.Node,
  openByDefault?: boolean,
  onToggle?: (isVisible: boolean) => void
|};

export default class Accordion extends React.Component<
  Props,
  {
    isOpen: boolean,
    accordionBodyElementId?: UUID,
    ...
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: !!props.openByDefault
    };
  }

  componentDidMount() {
    // We want server-side and client-side markup to match before mount.
    // The alternative would be to pass a pregenerated UUID from the parent.
    this.setState({
      accordionBodyElementId: `accordion-segment-${uuid4()}`
    });
  }

  render(): React.Node {
    const { heading, body } = this.props;

    const { isOpen, accordionBodyElementId } = this.state;

    return (
      <div>
        <div
          onClick={this.toggleBodyElementVisibility}
          onKeyDown={this.handleKeyDown}
          tabIndex="0"
          role="tab"
          aria-expanded={isOpen}
          aria-controls={accordionBodyElementId}
          className={styles.heading}
        >
          {heading({ isOpen: isOpen })}
        </div>
        <div
          className={classNames({
            [styles.body]: true,
            [styles.closed]: !isOpen
          })}
          id={accordionBodyElementId}
        >
          {isOpen && body}
        </div>
      </div>
    );
  }

  handleKeyDown: KeyboardEvent => void = event => {
    const keys = whatInput.keys();
    if (keys.indexOf('space') > -1 || keys.indexOf('enter') > -1) {
      this.toggleBodyElementVisibility();
      event.preventDefault();
    }
  };

  toggleBodyElementVisibility: () => void = () => {
    this.setState(
      {
        isOpen: !this.state.isOpen
      },
      () => {
        if (this.props.onToggle) {
          this.props.onToggle(this.state.isOpen);
        }
      }
    );
  };
}
